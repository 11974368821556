import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/article-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Body = makeShortcode("Body");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Body mdxType="Body">
      <p>{`とりあえずブログっぽいものを作成。`}<br parentName="p"></br>{`
`}{`何かアウトプットし続けないとどんどん忘れていきそうなので、覚えたことを書いていくようなところにする予定。`}<br parentName="p"></br>{`
`}{`あとは実装の壁打ち的な場所。`}</p>
    </Body>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      